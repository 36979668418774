.loader-container {
  font-size: 2rem;
  padding: 1.2rem;
  width: 100%;
  position: relative;
  background: rgba(102, 100, 100, 0.715);
  height: 100vh;
  display: flex;
  place-items: center;
}

.loader-container-pop-cart {
  background-color: white;
  width: 40rem;
  padding: 3rem 0rem;
  text-align: center;
  height: fit-content;
  padding-bottom: 2rem;
  margin: auto;
  border-radius: 0.5rem;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
}

.loader-container {
  padding: 5rem !important;
  border-radius: 0.5rem;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px;
}
.loader-container div h1 {
  color: var(--black);
  font-size: 2.5rem;
  margin-top: 1rem;
}

.loader-container div button {
  color: var(--black);
  padding: 1rem;
  margin-top: 1.8rem;
  font-size: 2rem;
  background-color: var(--green);
  color: white;
  border-radius: 0.5rem;
}
.loader-container div button:hover {
  background-color: tomato;
}

.loader-container-animation {
  background-color: none;
  width: 6rem;
  padding: 0.8rem 1.5rem;
  margin: auto;
  border: 1px solid green;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  place-items: center;
  animation: loader 7s infinite;
  transition: all 2s;
}

@keyframes loader {
  90% {
    transform: rotate(360deg);
    border: 1px solid orange;
  }
}

.loader-loading-text::before {
  content: "🥦";
  font-size: 3rem;
  animation: spin 7s infinite;
  transition: all 2s;
}

@keyframes spin {
  30% {
    content: "🥕";
  }
  35% {
    content: "🍊";
  }
  40% {
    content: "🌶️";
  }
  45% {
    content: "🍍";
  }
  50% {
    content: "🍏";
  }
  55% {
    content: "🍐";
  }
  60% {
    content: "🍉";
  }
  65% {
    content: "🍓";
  }
  70% {
    content: "🍒";
  }
  75% {
    content: "🍎";
  }
}

@media (max-width: 450px) {
  .loader-container div h1 {
    color: var(--black);
    font-size: 2.5rem;
  }
}
